<template>
	<simple-loading
		:is-loading="isLoading"
		:is-load-failed="loadFail"
		:show-loading-card="true"
		:show-load-fail-card="true"
		@reload="fetchData"
	>
		<div class="h-100" ref="element">
			<el-card>
				<div slot="header" class="clearfix">
					<span style="font-weight: bold"
						>Target Date: {{ displayDate }}</span
					>
					<span style="float: right" type="text"
						>Last Update: {{ lastUpdate }}</span
					>
				</div>

				<el-table
					:data="tableData"
					style="width: 100%"
					size="mini"
					stripe
					:height="size.height != null ? size.height - 130 : 100"
				>
					<el-table-column
						header-align="center"
						align="center"
						prop="cycle"
						label="Cycle"
					>
						<template slot-scope="scope">
							{{ scope.row.cycle == "Total" ? "Accumulated" : scope.row.cycle }}
						</template>
					</el-table-column>
					<el-table-column
						header-align="center"
						align="center"
						prop="received"
						label="Received"
					/>
					<el-table-column
						header-align="center"
						align="center"
						prop="relabeled"
						label="Relabled"
					/>
					<el-table-column
						header-align="center"
						align="center"
						prop="packed"
						label="Packed"
					/>
					<el-table-column
						header-align="center"
						align="center"
						prop="pending"
						label="Pending"
					>
						<template slot-scope="scope">
							<span style="color: red; font-weight: bold">{{
								scope.row.pending
							}}</span>
						</template>
					</el-table-column>
				</el-table>
			</el-card>
		</div>
	</simple-loading>
</template>

<script>
	import {
		ref,
		onMounted,
		computed,
		getCurrentInstance,
	} from "@vue/composition-api";
	import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
	import { fetcher, FetchMethod } from "@/libs/axios";
	import apis from "@configs/apis";
	import SimpleLoading from "@common-components/simple-loading/SimpleLoading.vue";
	import { formatNumber } from "@/utils/ui-helper";
	import { getZoned } from "@/utils/date-time-helper";
	import { createWrapperWebSocket } from "@/utils/websocket-helper";
	import websocketApis from "@configs/ws-apis";
	import webSocketMessageTypes from "@configs/ws-message-types";
	import { getLocalDateTimeString } from "@/utils/date-time-helper";
	import sizeHook from "@common-components/sizeHook";

	export default {
		components: {
			SimpleLoading,
		},
		setup(props, ctx) {
			const [size, element] = sizeHook();
			const $toast = getCurrentInstance().proxy.$toast;
			const socketRef = ref(null);

			const isLoading = ref(false);
			const loadFail = ref(false);
			const dataset = ref(null);
			const lastUpdate = ref(null);

			onMounted(async () => {
				await fetchData();

				createWrapperWebSocket(
					$toast,
					websocketApis.sortationRealTimeWS,
					socketRef,
					onWSMessage
				);
			});

			function onWSMessage(event) {
				const data = JSON.parse(event.data);
				if (
					data.messageType == webSocketMessageTypes.PRODUCTIVITY_PER_HOUR
				) {
					dataset.value = data.data;
					lastUpdate.value = getLocalDateTimeString();
				}
			}

			const fetchData = async () => {
				isLoading.value = true;
				loadFail.value = false;

				try {
					const response = await fetcher(
						apis.productivityPerHour,
						FetchMethod.GET
					);
					dataset.value = response;
					lastUpdate.value = getLocalDateTimeString();
				} catch (e) {
					loadFail.value = true;
				} finally {
					isLoading.value = false;
				}
			};

			const formatNumberWithDash = (value, canShow, isTotal) => {
				if (canShow) {
					if (isTotal) {
						return formatNumber(value);
					} else {
						if (value == 0) {
							return "-";
						} else {
							return formatNumber(value);
						}
					}
				} else {
					return "";
				}
			};

			const tableData = computed(() => {
				let result;
				if (dataset.value && dataset.value.data) {
					result = Object.keys(dataset.value.data).map((key) => {
						const record = dataset.value.data[key];
						return {
							cycle: record.cycle,
							received: formatNumberWithDash(
								record.received,
								record.canShow,
								key == "Total"
							),
							relabeled: formatNumberWithDash(
								record.relabeled,
								record.canShow,
								key == "Total"
							),
							packed: formatNumberWithDash(
								record.packed,
								record.canShow,
								key == "Total"
							),
							handovered: formatNumberWithDash(
								record.handovered,
								record.canShow,
								key == "Total"
							),
							pending: formatNumberWithDash(
								record.pending,
								record.canShow,
								key == "Total"
							),
						};
					});
				} else {
					result = [];
				}

				return result;
			});

			const displayDate = computed(() => {
				return getZoned(dataset.from).format("YYYY-MM-DD");
			});

			return {
				isLoading,
				loadFail,
				fetchData,
				tableData,
				displayDate,
				lastUpdate,

				size,
				element,
			};
		},
	};
</script>

<style scoped>
</style>